import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Footer from "../components/Shared/Footer/Footer";
import LinearChart from "../components/Chart/LinearChart";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import SecurityTableShort from "../components/Security/SecurityTableShort";
import { formatPercentage } from "../lib/utilities";
import { getTrendIcon } from "../lib/utilitiesComponent";
import BigHeader from "../components/Shared/Header/BigHeader";

const Security = () => {
  const { strategyCode } = useParams();
  const { sectorCode } = useParams();
  const { securityCode } = useParams();
  const [securities, setSecurities] = useState([]);
  const [strategy, setStrategy] = useState({});
  const [security, setSecurity] = useState([]);

  useEffect(() => {
    let cache =
      JSON.parse(sessionStorage.getItem(strategyCode)) ?? JSON.stringify([]);
    if (cache) {
      setStrategy(cache);
    }

    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });
  }, [strategyCode]);

  useEffect(() => {
    const strategy = JSON.parse(
      sessionStorage.getItem(strategyCode + "_strategy")
    );

    const securities = JSON.parse(
      sessionStorage.getItem(strategyCode + "_" + sectorCode + "_security_list")
    );

    const security = JSON.parse(
      sessionStorage.getItem(
        strategyCode + "_" + securityCode + "_security_details"
      )
    );

    if (strategy) {
      setStrategy(strategy);
    }

    if (securities) {
      setSecurities(securities);
    }

    if (security) {
      setSecurity(security);
    }

    getApi("security_list", {
      strategy: strategyCode,
      sector: sectorCode,
    }).then((r) => {
      setSecurities(r);
      sessionStorage.setItem(
        strategyCode + "_" + sectorCode + "_security_list",
        JSON.stringify(r)
      );
    });

    getApi("security_details", {
      strategy: strategyCode,
      security: securityCode,
    }).then((r) => {
      setSecurity(r);
      sessionStorage.setItem(
        strategyCode + "_" + securityCode + "_security_details",
        JSON.stringify(r)
      );
    });
  }, [strategyCode, sectorCode, securityCode]);

  return (
    <>
      <BigHeader strategy={strategy} />
      {strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>
                <Link to={`/strategy/${strategyCode}`}>{strategy["name"]}</Link>
              </li>
              <li>
                <Link to={`/strategy/${strategyCode}/${sectorCode}`}>
                  {sectorCode}
                </Link>
              </li>
              <li>{securityCode}</li>
            </ul>
          </section>
        </>
      ) : null}

      {strategy["color"] ? (
        <div
          className="sectorTitle"
          style={{ backgroundColor: `${strategy["color"]}` }}
        >
          {securityCode}
        </div>
      ) : null}

      {security["historical"] ? (
        <section className="cumulated-return">
          <div className="graph-container">
            <h2 className="">Shared price vs Druid Strategy</h2>
            <LinearChart
              data={security["historical"]}
              xAxisKey="end"
              pointKey="share_price"
              colorLine="#163470"
              secondLine="druid_strategy_adj"
              colorSecondLine="#459AD6"
              legend={true}
              tooltip={["signal"]}
              hasFilter={false}
            />
            <SecurityFooter security={security["security"]} />
          </div>
          <div className="sidebar">
            <div className="block-module list-table">
              <h4>Market data</h4>
              <ul>
                <li>
                  <p>Exchange</p>
                  <p>{security["security"]["exchange"]}</p>
                </li>
                <li>
                  <p>Select Currency</p>
                  <p>{security["security"]["currency"]}</p>
                </li>
                <li>
                  <p>Share Price</p>
                  <p>{security["security"]["share_price"]}</p>
                </li>
                <li>
                  <p>Market Cap</p>
                  <p>{security["security"]["market_cap"]}</p>
                </li>
                <li>
                  <p>Average 3M Daily volume</p>
                  <p>{security["security"]["average_3m_daily_volume"]}</p>
                </li>
              </ul>
            </div>
            <div className="sector-module">
              <h4>Druid sector selected comparables</h4>
              {security["related"] ? (
                <ul>
                  {security["related"].map((value, i) => (
                    <li key={i}>
                      <div>
                        <Link
                          reloadDocument
                          to={`/strategy/${strategyCode}/${sectorCode}/${value["symbol"]}`}
                        >
                          <span className="symbol">{value["symbol"]}</span>
                          <span className="name">{value["name"]}</span>
                        </Link>
                      </div>
                      <div className="signalCell">
                        <span>{value["signal"]}</span>
                      </div>
                      <div className="scoreCell">
                        <span>{formatPercentage(value["score"])}</span>
                        <span className="trend">
                          {getTrendIcon(value["score_trend"])}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading </span>
            <p>{securityCode}</p>
          </h2>
        </div>
      )}

      {securities ? (
        <SecurityTableShort
          securityList={securities}
          sectorCode={sectorCode}
          strategyCode={strategyCode}
        />
      ) : (
        ""
      )}

      <Footer />
    </>
  );
};

export default Security;

const Header = ({ data, risk }) => {
  return (
    <section
      className="sub-header"
      style={{
        backgroundColor: `${risk ? "#CACACA" : data["color"]}`,
        borderBottom: "7px",
        borderColor: `${risk ? "#FF0000" : data["color"]}`,
        borderStyle: "solid",
      }}
    >
      <div className="page-container md:px-11 px-5">
        <h1>{data["name"]}</h1>
        <p>{data["description"]}</p>
      </div>
    </section>
  );
};

export default Header;

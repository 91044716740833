import { colouredBar, drawdownRiskTable } from "../../lib/utilities";

const LineGraph = ({ data }) => {
  const drawdown =
    data["cumulated_returns"]["all"][
      data["cumulated_returns"]["all"].length - 1
    ]["drawdown_risk"];

  return (
    <div className="block-module bar-graph">
      <h2>Drawdown Risk</h2>
      <div>
        <p
          className="signal"
          style={{ color: drawdownRiskTable(drawdown, "color") }}
        >
          {drawdownRiskTable(drawdown, "label")}
        </p>
        <ul className="bar">
          <li style={{ backgroundColor: colouredBar(drawdown, 1) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 2) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 3) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 4) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 5) }}></li>
        </ul>
      </div>
    </div>
  );
};

export default LineGraph;

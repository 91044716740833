import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Footer from "../components/Shared/Footer/Footer";
import LinearChart from "../components/Chart/LinearChart";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import SectorsList from "../components/SectorsList/SectorsList";
import BigHeader from "../components/Shared/Header/BigHeader";

const Strategy = () => {
  const { strategyCode } = useParams();
  const [strategy, setStrategy] = useState({});
  const [sectors, setSectors] = useState([]);
  const [years, setYears] = useState("all");

  useEffect(() => {
    const strategia = sessionStorage.getItem(strategyCode + "_strategy");
    if (strategia !== "undefined") {
      const strategy = JSON.parse(strategia);
      setStrategy(strategy);
    }

    const listaSettori = sessionStorage.getItem(strategyCode + "_sector_list");
    if (listaSettori !== "undefined") {
      const sectors = JSON.parse(listaSettori);
      setSectors(sectors);
    }

    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });

    getApi("sector_list", { strategy: strategyCode }).then((r) => {
      setSectors(r);
      sessionStorage.setItem(strategyCode + "_sector_list", JSON.stringify(r));
    });
  }, [strategyCode]);

  useEffect(() => {
    if (
      strategy &&
      strategy["cumulated_returns"] &&
      strategy["cumulated_returns"]["forward"].length > 0
    ) {
      setYears("forward");
    }
  }, [strategy]);
  return (
    <>
      <BigHeader strategy={strategy} />

      {strategy && strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>{strategy["name"]}</li>
            </ul>
          </section>

          <section className="cumulated-return">
            <div className="graph-container">
              <h2>Cumulated Return</h2>
              <LinearChart
                data={strategy["cumulated_returns"]}
                xAxisKey="end_date"
                pointKey="value"
                colorLine="#163470"
                legend={true}
                tooltip={["end_date", "drawdown_risk", "value"]}
                hasFilter={true}
                setYears={setYears}
              />
            </div>
            <aside className="sidebar">
              <SecurityFooter
                footer={{
                  cagr: strategy["cagr"],
                  max_drawdown: strategy["max_drawdown"],
                }}
                years={years}
              />
            </aside>
          </section>
        </>
      ) : null}

      {sectors && sectors.length > 0 ? (
        <SectorsList strategyCode={strategyCode} sectors={sectors} />
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading</span>
            <p> {strategyCode}</p>
          </h2>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Strategy;

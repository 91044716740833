import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Footer from "../components/Shared/Footer/Footer";
import SecurityTable from "../components/Security/SecurityTable";
import SectorsList from "../components/SectorsList/SectorsList";
import BigHeader from "../components/Shared/Header/BigHeader";

const Sector = () => {
  const { strategyCode } = useParams();
  const { sectorCode } = useParams();
  const [strategy, setStrategy] = useState({});
  const [sectors, setSectors] = useState([]);
  const [securities, setSecurities] = useState([]);

  useEffect(() => {
    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });

    getApi("sector_list", { strategy: strategyCode }).then((r) => {
      setSectors(r);
      sessionStorage.setItem(strategyCode + "_sector_list", JSON.stringify(r));
    });
  }, [strategyCode]);

  useEffect(() => {
    const strategy = JSON.parse(
      sessionStorage.getItem(strategyCode + "_strategy")
    );
    const sectors = JSON.parse(
      sessionStorage.getItem(strategyCode + "_sector_list")
    );
    const securities = JSON.parse(
      sessionStorage.getItem(strategyCode + "_" + sectorCode + "_security_list")
    );
    if (strategy) {
      setStrategy(strategy);
    }
    if (sectors) {
      setSectors(sectors);
    }
    if (securities) {
      setSecurities(securities);
    }

    getApi("security_list", {
      strategy: strategyCode,
      sector: sectorCode,
    }).then((r) => {
      setSecurities(r);
      sessionStorage.setItem(
        strategyCode + "_" + sectorCode + "_security_list",
        JSON.stringify(r)
      );
    });
  }, [strategyCode, sectorCode]);

  return (
    <>
      <BigHeader strategy={strategy} />
      {strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>
                <Link to={`/strategy/${strategyCode}`}>{strategy["name"]}</Link>
              </li>
              <li>{sectorCode}</li>
            </ul>
          </section>

          <div
            className="sectorTitle"
            style={{ backgroundColor: `${strategy["color"]}` }}
          >
            {sectorCode}
          </div>
        </>
      ) : null}

      {securities.length ? (
        <SecurityTable securityList={securities} sectorCode={sectorCode} />
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading </span>
            <p>{sectorCode}</p>
          </h2>
        </div>
      )}

      {sectors.length > 0 ? (
        <SectorsList strategyCode={strategyCode} sectors={sectors} />
      ) : (
        ""
      )}

      <Footer />
    </>
  );
};

export default Sector;
